import React from 'react';
import LazyLoad from 'react-lazyload';
import s from './MainPage.module.scss';
import Videos from '../../Videos/Videos';

const MainPage = () => (
    <>
        <div className={s.title}>
            Top video
            <span className={s.date}>
                {new Date().getFullYear()}
            </span>
        </div>
        <LazyLoad height={200}>
            <Videos />
        </LazyLoad>
    </>
);

export default MainPage;
