import { Dispatch } from 'react';
import CURRENT_PLAYER from './types/videoPlayersTypes';

export default function setCurrentPlayer(videoRefApi: any | HTMLVideoElement) {
    return (dispatch: Dispatch<object>) => {
        dispatch({
            type: CURRENT_PLAYER,
            current: videoRefApi.current,
            setIsPlaying: videoRefApi.setIsPlaying,
        });
        dispatch({
            type: 'LOADED',
        });
    };
}
