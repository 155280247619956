import React from 'react';
import s from './Page404.module.scss';

const Page404 = () => (
    <div className={s.wrapper}>
        <div>
            <h2 className={s.Page404}>
                Ошибка 404
                {' '}
                <br />
                {' '}
                страница не найдена.
            </h2>
            <p className={s.description}>Попробуйте пожалуйста другой запрос.</p>
        </div>
    </div>
);

export default Page404;
