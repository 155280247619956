declare global {
    interface Document {
        mozCancelFullScreen?: () => void;
        msExitFullscreen?: () => void;
        webkitExitFullscreen?: () => void;
        mozFullScreenElement?: Element;
        msFullscreenElement?: Element;
        webkitFullscreenElement?: Element;
        webkitCancelFullScreen?: () => void;
    }

    interface HTMLElement {
        msRequestFullscreen?: () => void;
        mozRequestFullscreen?: () => void;
        webkitRequestFullscreen?: () => void;
    }

    interface Element {
        msRequestFullscreen?: () => void
        mozRequestFullScreen?: () => void
        webkitRequestFullScreen?: () => void
    }
}

const toggleFullScreen = (
    isFullScreen: boolean,
    refPlayerWrapper: React.RefObject<HTMLDivElement>,
    setIsFullScreen: (value: (((prevState: boolean) => boolean) | boolean)) => void,
) => {
    if (isFullScreen) {
        setIsFullScreen(false);
        if (document.exitFullscreen) document.exitFullscreen();

        else if (document.mozCancelFullScreen) document.mozCancelFullScreen();

        else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();

        else if (document.msExitFullscreen) document.msExitFullscreen();
    } else {
        if (refPlayerWrapper.current) {
            if ('requestFullscreen' in document.documentElement) {
                refPlayerWrapper.current.requestFullscreen();
            } else if ('mozRequestFullScreen' in document.documentElement) {
                // @ts-ignore
                refPlayerWrapper.current.mozRequestFullScreen();
            } else if ('webkitRequestFullScreen' in document.documentElement) {
                // @ts-ignore
                refPlayerWrapper.current.webkitRequestFullScreen();
            }
        }
        setIsFullScreen(true);
    }
};

export default toggleFullScreen;
