import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import s from './CardPage.module.scss';
import Video from '../../Videos/Video';
import MoreCard from './MoreCard';
import {
    ContentFragment,
    useCompactLazyQuery,
    useContentByIdLazyQuery,
} from '../../../generated/graphql';
import ErrorPage from '../ErrorPage/ErrorPage';
import Loader from '../../Loader/Loader';
import Page404 from '../Page404/Page404';

interface CardPageProps {
    verify: boolean;
    flowURL: string;
}

const CardPage = (props: CardPageProps) => {
    const { verify, flowURL } = props;

    const [
        fetchAdditionalContents,
        {
            data: additionalContentsData,
        },
    ] = useCompactLazyQuery();

    const [fetchContent,
        {
            data: contentData,
            loading: contentLoading,
            error: contentError,
        },
    ] = useContentByIdLazyQuery();

    const match = useRouteMatch<{ id: string }>();

    const { params: { id } } = match;

    const content = contentData?.point?.service?.content;
    const additionalContents = additionalContentsData?.point?.service?.contents;

    useEffect(() => {
        if (verify && id) {
            fetchContent({
                variables: {
                    id,
                },
            });
            fetchAdditionalContents();
        }
    }, [id, verify]);

    if (contentError && contentError.message.indexOf('bad key') !== -1) {
        return <Page404 />;
    }

    if (contentError) {
        return <ErrorPage />;
    }

    if (contentLoading) {
        return <Loader />;
    }

    if (contentData && !contentData.point?.service.content) {
        return <Page404 />;
    }

    if (!verify) {
        window.location.href = flowURL;
    }

    return (
        <>
            <div className={s.wrapper}>
                {
                    content
                        ? (
                            <Video
                                content={content}
                                authorized={verify}
                                flowUrl={flowURL}
                            />
                        ) : <div />
                }
                <div className={s.title}>
                    SEE MORE
                </div>
                <div className={s.moreWrapper}>
                    {
                        additionalContents ? additionalContents.map((item: ContentFragment) => (
                            <MoreCard
                                key={item.id}
                                content={item}
                            />
                        )) : <></>
                    }
                </div>
            </div>

        </>
    );
};

export default CardPage;
