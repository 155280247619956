import React from 'react';
import s from './Loader.module.scss';

const Loader = () => (
    <div className={s.loader}>
        <div className={s.whirlpool} />
    </div>
);

export default Loader;
