import React from 'react';
import { Link } from 'react-router-dom';
import s from './Footer.module.scss';
import useFooterApi from '../../api/useFooterApi';

interface FooterProps {
    verify: boolean;
}

const Footer = (props: FooterProps) => {
    const { verify } = props;
    const html = useFooterApi();

    return (
        <footer className={s.footer}>
            {
                !verify
                || (
                    <div className={s.ofertaLink}>
                        <span>Подписка включена! Качай без ограничений.</span>
                        <Link to="/user/conditions/">Подробнее о подписке.</Link>
                    </div>
                )
            }
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </footer>
    );
};

export default Footer;
