export const firebaseProjectToken = '07a711cf-2e48-4dff-932e-3e976ac166eb';

export const firebaseVapidKey = 'BIYLa1Yrbxr_aKCbNzxyK7J_euJ7A8AbTU7B6nC6aXdRiyjbquVLjFGLgciTINdgMF_ZKj01DnQEsGagtMBhr14';

export const firebaseConfig = {
    apiKey: 'AIzaSyBaxbpEE9bOzWIG-ayY8-dPm2AGtn_lDjs',
    authDomain: 'ero-rb-life.firebaseapp.com',
    projectId: 'ero-rb-life',
    storageBucket: 'ero-rb-life.appspot.com',
    messagingSenderId: '962648911578',
    appId: '1:962648911578:web:61cc9c625460bbbd65ffe3',
};
