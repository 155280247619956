import React, { FC } from 'react';
import { ReactComponent as IconPause } from '../../assets/images/pause-solid.svg';
import { ReactComponent as IconPlay } from '../../assets/images/play-solid.svg';

type PlayPauseIconsType = {
    onClick?(): void,
    isPlaying: boolean,
};

const PlayPauseIcons: FC<PlayPauseIconsType> = ({ isPlaying, onClick }) => (
    isPlaying
        ? (<IconPause onClick={onClick} />)
        : (<IconPlay onClick={onClick} />)
);

PlayPauseIcons.defaultProps = {
    onClick() {
    },
};

export default PlayPauseIcons;
