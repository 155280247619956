import React, {
    useState,
    useEffect,
} from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import s from './Videos.module.scss';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import {
    ContentFragment,
    useSetLikeMutation,
    useSetViewMutation,
} from '../../generated/graphql';
import { BASE_PATH } from '../../constants/api';

export type Props = {
    content: ContentFragment;
    authorized: boolean;
    flowUrl: string;
};

export const getVideoAttributes = (contentData: ContentFragment) => {
    const preview = contentData && contentData.previews && contentData.previews.length
        ? contentData.previews[0] : null;
    const previewLink = preview ? `${BASE_PATH}/gate/f/${preview.link}` : '';
    const videoSrc = contentData ? `${BASE_PATH}/gate/p/${contentData.link}` : '';

    return {
        previewLink,
        videoSrc,
    };
};

const Video = (props: Props) => {
    const {
        content, authorized, flowUrl,
    } = props;
    const [setLikeMutation] = useSetLikeMutation();
    const [setViewMutation] = useSetViewMutation();
    const [isPlaying, setIsPlaying] = useState(false);
    const [liked, setLiked] = useState(content.like.isLiked);

    const handleScrollYAuto = () => {
        document.body.style.overflowY = 'auto';
    };
    const handleScrollYHidden = () => {
        document.body.style.overflowY = 'hidden';
    };

    useEffect(() => {
        if (isPlaying) {
            handleScrollYHidden();
        } else {
            handleScrollYAuto();
        }
        return () => {
            handleScrollYAuto();
        };
    }, [isPlaying]);

    const { previewLink, videoSrc } = getVideoAttributes(content);

    const onPlayHandler = (): void => {
        if (!authorized) {
            window.location.href = flowUrl;
            return;
        }

        if (isPlaying) {
            setIsPlaying(false);
        } else {
            setIsPlaying(true);
        }
    };

    const onLikeHandler = (): void => {
        setLikeMutation({
            variables: {
                id: content.like.id,
            },
        })
            .then(() => {
                setLiked((st) => !st);
            })
            .catch(() => {});
    };

    const setWatched = () => {
        if (!content.view.isViewed) {
            setViewMutation({
                variables: {
                    id: content.view.id,
                },
            })
                .catch(() => {});
        }
    };

    return (
        <div className={classNames(s.video, isPlaying ? s.playing : '')} key={content.id}>

            <div className={s.videoPlay}>
                <VideoPlayer
                    isPlayingProp={onPlayHandler}
                    className={s.player}
                    onPlay={setWatched}
                    poster={previewLink}
                    src={videoSrc}
                    authorized={authorized}
                    flowUrl={flowUrl}
                />
            </div>
            <div className={s.videoText}>
                <Link to={`/card_id/${content.id}`}>
                    <h5 className={s.title}>{content.name}</h5>
                </Link>
                <div className={s.bottom}>
                    <button type="button" className={classNames(liked ? `${s.like} ${s.active}` : s.like, authorized ? null : `${s.like} ${s.disable}`)} onClick={onLikeHandler}>
                        {content.like.total}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Video;
