import React from 'react';
import s from '../Page404/Page404.module.scss';

const ErrorPage = () => (
    <div className={s.wrapper}>
        <h2 className={s.Page404}>
            Ошибка.
            <br />
            {' '}
            Что-то пошло не так.
        </h2>
        <p className={s.description}>Попробуйте пожалуйста позже.</p>
    </div>
);

export default ErrorPage;
