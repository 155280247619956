import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Category = {
  __typename?: 'Category';
  alias: Scalars['String'];
  contents: Array<Content>;
  id: Scalars['ID'];
  localizations?: Maybe<Array<I18nCategory>>;
  name: Scalars['String'];
  params: Array<MetaContentParam>;
  priority: Scalars['Int'];
  tags?: Maybe<Array<Tag>>;
  totalContent: Scalars['Int'];
  totalContents: Scalars['Int'];
  type: Scalars['String'];
};


export type CategoryContentsArgs = {
  filter?: InputMaybe<ContentFilter>;
  library?: InputMaybe<LibraryFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  paramFilters?: InputMaybe<ParamFilters>;
  sort?: InputMaybe<SortByParam>;
  tagID?: InputMaybe<Scalars['ID']>;
};


export type CategoryLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};


export type CategoryParamsArgs = {
  name: Scalars['String'];
};


export type CategoryTotalContentsArgs = {
  filter?: InputMaybe<ContentFilter>;
  library?: InputMaybe<LibraryFilter>;
  paramFilters?: InputMaybe<ParamFilters>;
  sort?: InputMaybe<SortByParam>;
  tagID?: InputMaybe<Scalars['ID']>;
};

export type ComplexParam = {
  __typename?: 'ComplexParam';
  number?: Maybe<Scalars['Int']>;
  numbers?: Maybe<Array<Scalars['Int']>>;
  string?: Maybe<Scalars['String']>;
  strings?: Maybe<Array<Scalars['String']>>;
  type: Scalars['String'];
};

export type Content = {
  __typename?: 'Content';
  categories: Array<Category>;
  category: Category;
  description?: Maybe<Scalars['String']>;
  fileExt: Scalars['String'];
  id: Scalars['ID'];
  isInLibrary: Scalars['Boolean'];
  like: Like;
  link: Scalars['String'];
  listeningPosition: Scalars['Float'];
  localizations?: Maybe<Array<I18nContent>>;
  metaContentParams?: Maybe<Array<MetaContentParam>>;
  myRating?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  previews?: Maybe<Array<Preview>>;
  priority: Scalars['Int'];
  protected: Scalars['Boolean'];
  rating?: Maybe<Scalars['Float']>;
  readingPosition: Scalars['Float'];
  relations: Array<Content>;
  tags?: Maybe<Array<Tag>>;
  totalRelations: Scalars['Int'];
  type: Scalars['String'];
  view: View;
};


export type ContentIsInLibraryArgs = {
  type: LibraryType;
};


export type ContentLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};


export type ContentRelationsArgs = {
  categoryID?: InputMaybe<Scalars['ID']>;
};


export type ContentTotalRelationsArgs = {
  categoryID?: InputMaybe<Scalars['ID']>;
};

export type ContentFilter = {
  typeName?: InputMaybe<Scalars['String']>;
};

export type I18nCategory = {
  __typename?: 'I18nCategory';
  id: Scalars['ID'];
  lang: Scalars['String'];
  name: Scalars['String'];
};

export type I18nContent = {
  __typename?: 'I18nContent';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lang: Scalars['String'];
  link: Scalars['String'];
  name: Scalars['String'];
};

export type I18nMetaContentParam = {
  __typename?: 'I18nMetaContentParam';
  id: Scalars['ID'];
  lang: Scalars['String'];
  value: Scalars['String'];
};

export type I18nTag = {
  __typename?: 'I18nTag';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  lang: Scalars['String'];
};

export type LibraryFilter = {
  logicOperator: LogicOperator;
  negative: Scalars['Boolean'];
  values: Array<LibraryType>;
};

export enum LibraryType {
  Readed = 'READED',
  ReadNow = 'READ_NOW',
  Top = 'TOP'
}

export type Like = {
  __typename?: 'Like';
  id: Scalars['ID'];
  isLiked: Scalars['Boolean'];
  total: Scalars['Int'];
};

export enum LogicOperator {
  And = 'and',
  Or = 'or'
}

export type MetaContentParam = Param & {
  __typename?: 'MetaContentParam';
  complex?: Maybe<ComplexParam>;
  id: Scalars['ID'];
  localizations?: Maybe<Array<I18nMetaContentParam>>;
  name: Scalars['String'];
  value: Scalars['String'];
};


export type MetaContentParamLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addToLibrary?: Maybe<Content>;
  deactivateSubs?: Maybe<Scalars['Boolean']>;
  like: Like;
  listeningPosition?: Maybe<Content>;
  rating?: Maybe<Content>;
  readingPosition?: Maybe<Content>;
  removeFromLibrary?: Maybe<Content>;
  view: View;
};


export type MutationAddToLibraryArgs = {
  id: Scalars['ID'];
  type: LibraryType;
};


export type MutationLikeArgs = {
  id: Scalars['ID'];
};


export type MutationListeningPositionArgs = {
  id: Scalars['ID'];
  position: Scalars['Float'];
};


export type MutationRatingArgs = {
  id: Scalars['ID'];
  rate: Scalars['Int'];
};


export type MutationReadingPositionArgs = {
  id: Scalars['ID'];
  position: Scalars['Float'];
};


export type MutationRemoveFromLibraryArgs = {
  id: Scalars['ID'];
  type: LibraryType;
};


export type MutationViewArgs = {
  id: Scalars['ID'];
};

export enum Order {
  Asc = 'asc',
  Desc = 'desc',
  Rand = 'rand'
}

export type Param = {
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Base64 encoded JSON object */
  value: Scalars['String'];
};

export type ParamFilter = {
  paramName: Scalars['String'];
  value?: InputMaybe<Array<Scalars['String']>>;
};

export type ParamFilters = {
  logicOperator: LogicOperator;
  negative: Scalars['Boolean'];
  params?: InputMaybe<Array<InputMaybe<ParamFilter>>>;
};

export type Point = {
  __typename?: 'Point';
  flowURL?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  service: Service;
  sid: Scalars['String'];
  sskey: Scalars['String'];
  tariff: Scalars['Int'];
  verify?: Maybe<Scalars['Boolean']>;
};

export type Preview = {
  __typename?: 'Preview';
  ext: Scalars['String'];
  id: Scalars['ID'];
  link: Scalars['String'];
  priority: Scalars['Int'];
  size: Scalars['Int'];
  type: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  languages: Array<Scalars['String']>;
  point?: Maybe<Point>;
  user?: Maybe<User>;
};

export enum SearchField {
  Creator = 'creator',
  Description = 'description',
  ExtraShortDescription = 'extraShortDescription',
  Name = 'name',
  ShortDescription = 'shortDescription'
}

export type Service = {
  __typename?: 'Service';
  alias: Scalars['String'];
  bookLibrary: Array<Content>;
  categories?: Maybe<Array<Category>>;
  category?: Maybe<Category>;
  categoryByAlias?: Maybe<Category>;
  content?: Maybe<Content>;
  contents: Array<Content>;
  id: Scalars['ID'];
  languages: Array<Scalars['String']>;
  name: Scalars['String'];
  params: Array<MetaContentParam>;
  search: Array<Content>;
  searchByField: Array<Content>;
  tag?: Maybe<Tag>;
  tagByName?: Maybe<Tag>;
  tags?: Maybe<Array<Tag>>;
  totalContent: Scalars['Int'];
  totalContents: Scalars['Int'];
};


export type ServiceBookLibraryArgs = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  sort?: InputMaybe<SortByParam>;
  type: LibraryType;
};


export type ServiceCategoryArgs = {
  id: Scalars['ID'];
};


export type ServiceCategoryByAliasArgs = {
  alias: Scalars['String'];
};


export type ServiceContentArgs = {
  id: Scalars['ID'];
};


export type ServiceContentsArgs = {
  filter?: InputMaybe<ContentFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  paramFilters?: InputMaybe<ParamFilters>;
  sort?: InputMaybe<SortByParam>;
};


export type ServiceParamsArgs = {
  name: Scalars['String'];
};


export type ServiceSearchArgs = {
  fields?: Array<SearchField>;
  lang?: Array<Scalars['String']>;
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  query: Scalars['String'];
  sort?: InputMaybe<SortByParam>;
};


export type ServiceSearchByFieldArgs = {
  fields?: InputMaybe<Array<SearchField>>;
  lang?: Scalars['String'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  query: Scalars['String'];
  sort?: InputMaybe<SortByParam>;
};


export type ServiceTagArgs = {
  id: Scalars['ID'];
};


export type ServiceTagByNameArgs = {
  name: Scalars['String'];
};


export type ServiceTotalContentsArgs = {
  filter?: InputMaybe<ContentFilter>;
  paramFilters?: InputMaybe<ParamFilters>;
  sort?: InputMaybe<SortByParam>;
};

export type SortByParam = {
  order: Order;
  paramName?: InputMaybe<Scalars['String']>;
};

export type Tag = {
  __typename?: 'Tag';
  contents: Array<Content>;
  description?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  localizations?: Maybe<Array<I18nTag>>;
  name: Scalars['String'];
  priority: Scalars['Int'];
  totalContent: Scalars['Int'];
  totalContents: Scalars['Int'];
};


export type TagContentsArgs = {
  categoryID?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<ContentFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  paramFilters?: InputMaybe<ParamFilters>;
  sort?: InputMaybe<SortByParam>;
};


export type TagLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};


export type TagTotalContentsArgs = {
  categoryID?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<ContentFilter>;
  paramFilters?: InputMaybe<ParamFilters>;
};

export type User = {
  __typename?: 'User';
  msisdn: Scalars['Int'];
  profileId: Scalars['Int'];
  sid: Scalars['String'];
  tariff: Scalars['Int'];
};

export type View = {
  __typename?: 'View';
  id: Scalars['ID'];
  isViewed: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type VerifyQueryVariables = Exact<{ [key: string]: never; }>;


export type VerifyQuery = { __typename?: 'Query', user?: { __typename?: 'User', msisdn: number, profileId: number, sid: string, tariff: number } | null, point?: { __typename?: 'Point', verify?: boolean | null, flowURL?: string | null } | null };

export type CompactQueryVariables = Exact<{
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
}>;


export type CompactQuery = { __typename?: 'Query', point?: { __typename?: 'Point', id: string, sid: string, sskey: string, tariff: number, verify?: boolean | null, flowURL?: string | null, service: { __typename?: 'Service', id: string, alias: string, totalContent: number, contents: Array<{ __typename?: 'Content', id: string, description?: string | null, link: string, name: string, type: string, priority: number, view: { __typename?: 'View', id: string, isViewed: boolean, total: number }, like: { __typename?: 'Like', id: string, isLiked: boolean, total: number }, category: { __typename?: 'Category', id: string, alias: string, name: string, totalContent: number }, tags?: Array<{ __typename?: 'Tag', id: string, displayName: string, priority: number }> | null, previews?: Array<{ __typename?: 'Preview', id: string, link: string, type: string }> | null }> } } | null };

export type ContentsQueryVariables = Exact<{
  limit?: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type ContentsQuery = { __typename?: 'Query', point?: { __typename?: 'Point', id: string, service: { __typename?: 'Service', id: string, contents: Array<{ __typename?: 'Content', id: string, description?: string | null, link: string, name: string, type: string, priority: number, view: { __typename?: 'View', id: string, isViewed: boolean, total: number }, like: { __typename?: 'Like', id: string, isLiked: boolean, total: number }, category: { __typename?: 'Category', id: string, alias: string, name: string, totalContent: number }, tags?: Array<{ __typename?: 'Tag', id: string, displayName: string, priority: number }> | null, previews?: Array<{ __typename?: 'Preview', id: string, link: string, type: string }> | null }> } } | null };

export type ContentByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ContentByIdQuery = { __typename?: 'Query', point?: { __typename?: 'Point', id: string, verify?: boolean | null, service: { __typename?: 'Service', id: string, content?: { __typename?: 'Content', id: string, description?: string | null, link: string, name: string, type: string, priority: number, view: { __typename?: 'View', id: string, isViewed: boolean, total: number }, like: { __typename?: 'Like', id: string, isLiked: boolean, total: number }, category: { __typename?: 'Category', id: string, alias: string, name: string, totalContent: number }, tags?: Array<{ __typename?: 'Tag', id: string, displayName: string, priority: number }> | null, previews?: Array<{ __typename?: 'Preview', id: string, link: string, type: string }> | null } | null } } | null };

export type LikeFragment = { __typename?: 'Like', id: string, isLiked: boolean, total: number };

export type SetLikeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SetLikeMutation = { __typename?: 'Mutation', like: { __typename?: 'Like', id: string, isLiked: boolean, total: number } };

export type SetViewMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SetViewMutation = { __typename?: 'Mutation', view: { __typename?: 'View', id: string, isViewed: boolean, total: number } };

export type ViewFragment = { __typename?: 'View', id: string, isViewed: boolean, total: number };

export type ContentFragment = { __typename?: 'Content', id: string, description?: string | null, link: string, name: string, type: string, priority: number, view: { __typename?: 'View', id: string, isViewed: boolean, total: number }, like: { __typename?: 'Like', id: string, isLiked: boolean, total: number }, category: { __typename?: 'Category', id: string, alias: string, name: string, totalContent: number }, tags?: Array<{ __typename?: 'Tag', id: string, displayName: string, priority: number }> | null, previews?: Array<{ __typename?: 'Preview', id: string, link: string, type: string }> | null };

export type PreviewFragment = { __typename?: 'Preview', id: string, link: string, type: string };

export type TagFragment = { __typename?: 'Tag', id: string, displayName: string, priority: number };

export type UserFragment = { __typename?: 'User', msisdn: number, profileId: number, sid: string, tariff: number };

export const ViewFragmentDoc = gql`
    fragment View on View {
  id
  isViewed
  total
}
    `;
export const LikeFragmentDoc = gql`
    fragment Like on Like {
  id
  isLiked
  total
}
    `;
export const TagFragmentDoc = gql`
    fragment Tag on Tag {
  id
  displayName
  priority
}
    `;
export const PreviewFragmentDoc = gql`
    fragment Preview on Preview {
  id
  link
  type
}
    `;
export const ContentFragmentDoc = gql`
    fragment Content on Content {
  id
  description
  link
  name
  type
  priority
  view {
    ...View
  }
  like {
    ...Like
  }
  category {
    id
    alias
    name
    totalContent
  }
  tags {
    ...Tag
  }
  previews {
    ...Preview
  }
}
    ${ViewFragmentDoc}
${LikeFragmentDoc}
${TagFragmentDoc}
${PreviewFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  msisdn
  profileId
  sid
  tariff
}
    `;
export const VerifyDocument = gql`
    query Verify {
  user {
    ...User
  }
  point {
    verify
    flowURL
  }
}
    ${UserFragmentDoc}`;

/**
 * __useVerifyQuery__
 *
 * To run a query within a React component, call `useVerifyQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyQuery({
 *   variables: {
 *   },
 * });
 */
export function useVerifyQuery(baseOptions?: Apollo.QueryHookOptions<VerifyQuery, VerifyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyQuery, VerifyQueryVariables>(VerifyDocument, options);
      }
export function useVerifyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyQuery, VerifyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyQuery, VerifyQueryVariables>(VerifyDocument, options);
        }
export type VerifyQueryHookResult = ReturnType<typeof useVerifyQuery>;
export type VerifyLazyQueryHookResult = ReturnType<typeof useVerifyLazyQuery>;
export type VerifyQueryResult = Apollo.QueryResult<VerifyQuery, VerifyQueryVariables>;
export const CompactDocument = gql`
    query Compact($limit: Int! = 4, $offset: Int! = 0) {
  point {
    id
    sid
    sskey
    tariff
    verify
    flowURL
    service {
      id
      alias
      totalContent
      contents(limit: $limit, offset: $offset) {
        ...Content
      }
    }
  }
}
    ${ContentFragmentDoc}`;

/**
 * __useCompactQuery__
 *
 * To run a query within a React component, call `useCompactQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompactQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCompactQuery(baseOptions?: Apollo.QueryHookOptions<CompactQuery, CompactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompactQuery, CompactQueryVariables>(CompactDocument, options);
      }
export function useCompactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompactQuery, CompactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompactQuery, CompactQueryVariables>(CompactDocument, options);
        }
export type CompactQueryHookResult = ReturnType<typeof useCompactQuery>;
export type CompactLazyQueryHookResult = ReturnType<typeof useCompactLazyQuery>;
export type CompactQueryResult = Apollo.QueryResult<CompactQuery, CompactQueryVariables>;
export const ContentsDocument = gql`
    query Contents($limit: Int! = 8, $offset: Int = 0) {
  point {
    id
    service {
      id
      contents(limit: $limit, offset: $offset) {
        ...Content
      }
    }
  }
}
    ${ContentFragmentDoc}`;

/**
 * __useContentsQuery__
 *
 * To run a query within a React component, call `useContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useContentsQuery(baseOptions?: Apollo.QueryHookOptions<ContentsQuery, ContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentsQuery, ContentsQueryVariables>(ContentsDocument, options);
      }
export function useContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentsQuery, ContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentsQuery, ContentsQueryVariables>(ContentsDocument, options);
        }
export type ContentsQueryHookResult = ReturnType<typeof useContentsQuery>;
export type ContentsLazyQueryHookResult = ReturnType<typeof useContentsLazyQuery>;
export type ContentsQueryResult = Apollo.QueryResult<ContentsQuery, ContentsQueryVariables>;
export const ContentByIdDocument = gql`
    query ContentById($id: ID!) {
  point {
    id
    verify
    service {
      id
      content(id: $id) {
        ...Content
      }
    }
  }
}
    ${ContentFragmentDoc}`;

/**
 * __useContentByIdQuery__
 *
 * To run a query within a React component, call `useContentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContentByIdQuery(baseOptions: Apollo.QueryHookOptions<ContentByIdQuery, ContentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentByIdQuery, ContentByIdQueryVariables>(ContentByIdDocument, options);
      }
export function useContentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentByIdQuery, ContentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentByIdQuery, ContentByIdQueryVariables>(ContentByIdDocument, options);
        }
export type ContentByIdQueryHookResult = ReturnType<typeof useContentByIdQuery>;
export type ContentByIdLazyQueryHookResult = ReturnType<typeof useContentByIdLazyQuery>;
export type ContentByIdQueryResult = Apollo.QueryResult<ContentByIdQuery, ContentByIdQueryVariables>;
export const SetLikeDocument = gql`
    mutation SetLike($id: ID!) {
  like(id: $id) {
    ...Like
  }
}
    ${LikeFragmentDoc}`;
export type SetLikeMutationFn = Apollo.MutationFunction<SetLikeMutation, SetLikeMutationVariables>;

/**
 * __useSetLikeMutation__
 *
 * To run a mutation, you first call `useSetLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLikeMutation, { data, loading, error }] = useSetLikeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetLikeMutation(baseOptions?: Apollo.MutationHookOptions<SetLikeMutation, SetLikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLikeMutation, SetLikeMutationVariables>(SetLikeDocument, options);
      }
export type SetLikeMutationHookResult = ReturnType<typeof useSetLikeMutation>;
export type SetLikeMutationResult = Apollo.MutationResult<SetLikeMutation>;
export type SetLikeMutationOptions = Apollo.BaseMutationOptions<SetLikeMutation, SetLikeMutationVariables>;
export const SetViewDocument = gql`
    mutation SetView($id: ID!) {
  view(id: $id) {
    ...View
  }
}
    ${ViewFragmentDoc}`;
export type SetViewMutationFn = Apollo.MutationFunction<SetViewMutation, SetViewMutationVariables>;

/**
 * __useSetViewMutation__
 *
 * To run a mutation, you first call `useSetViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setViewMutation, { data, loading, error }] = useSetViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetViewMutation(baseOptions?: Apollo.MutationHookOptions<SetViewMutation, SetViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetViewMutation, SetViewMutationVariables>(SetViewDocument, options);
      }
export type SetViewMutationHookResult = ReturnType<typeof useSetViewMutation>;
export type SetViewMutationResult = Apollo.MutationResult<SetViewMutation>;
export type SetViewMutationOptions = Apollo.BaseMutationOptions<SetViewMutation, SetViewMutationVariables>;