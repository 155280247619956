import CURRENT_PLAYER from '../actions/types/videoPlayersTypes';

export type VideoCurrentActions = {
    type: typeof CURRENT_PLAYER,
    current: HTMLVideoElement
    setIsPlaying(): void,
};

export type VideoCurrentState = {
    current: HTMLVideoElement,
    setIsPlaying(b: boolean): void;
};

export default function videoCurrent(state: VideoCurrentState, action: VideoCurrentActions) {
    switch (action.type) {
        case CURRENT_PLAYER:
            return {
                ...state,
                current: action.current,
                setIsPlaying: action.setIsPlaying,
            };
        default:
            return { ...state };
    }
}
