import React, { FC } from 'react';
import { ReactComponent as PromoLogoSVG } from '../../assets/images/promo-logo.svg';
import s from './PromoBannerItem.module.scss';

export type Props = {
    image: string
};

const PromoBannerItem:FC<Props> = (props: Props) => {
    const { image } = props;

    return (
        <div className={s.promoBannerItem}>
            <div className={s.promoLogoIcon}>
                <PromoLogoSVG />
            </div>
            <img className={s.image} src={image} alt="" />
        </div>
    );
};

export default PromoBannerItem;
