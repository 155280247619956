import React from 'react';
import { Link } from 'react-router-dom';
import s from './CardPage.module.scss';
import { getVideoAttributes } from '../../Videos/Video';
import { ContentFragment } from '../../../generated/graphql';

export type Props = {
    content: ContentFragment;
};

const MoreCard = (props: Props) => {
    const { content } = props;
    const { previewLink } = getVideoAttributes(content);
    return (
        <Link to={`/card_id/${content.id}`} className={s.moreCard_wrapper}>
            <div className={s.moreCard_button}>PLAY</div>
            <img className={s.moreCard_image} src={previewLink} alt="" />
            <div className={s.moreCard_title}>{content.name}</div>
        </Link>
    );
};

export default MoreCard;
