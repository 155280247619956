import React, {
    useEffect, useRef, useState,
} from 'react';
import Video from './Video';
import {
    CompactDocument,
    ContentFragment,
    useCompactLazyQuery,
} from '../../generated/graphql';
import Loader from '../Loader/Loader';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import s from './Videos.module.scss';
import PromoBanner from '../PromoBanner/PromoBanner';

const Videos = (() => {
    const [
        fetchContents,
        {
            data,
            loading,
            fetchMore,
            error,
        },
    ] = useCompactLazyQuery();

    const [pushedMoreContents, setPushedMoreContents] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const contentRef = useRef<HTMLDivElement>(null);

    const contents = (data && data.point && data.point.service)
        ? data.point.service.contents : null;
    const authorized = (data && data.point && data.point.verify) ? data.point.verify : false;
    const flowUrl = (data && data.point && data.point.flowURL) ? data.point.flowURL : '';
    const totalContent = (data && data.point && data.point.service
        && data.point.service.totalContent)
        ? data.point.service.totalContent : null;

    const fetchMoreContents = async (contentsArr: ContentFragment[]) => {
        if (fetchMore) {
            setShowLoader(true);
            await fetchMore({
                query: CompactDocument,
                variables: {
                    limit: 8,
                    offset: (contentsArr.length) ? contentsArr.length : 0,
                },
            });
            setShowLoader(false);
        }
    };

    useEffect(() => {
        fetchContents({
            variables: {
                limit: 4,
                offset: 0,
            },
        });
    }, []);

    useEffect(() => {
        const loadMore = () => {
            if (contentRef?.current) {
                const contentBottom = +contentRef?.current?.offsetTop
                    + +contentRef?.current?.offsetHeight;
                const windowScroll = document.body.scrollTop
                    + document.body.clientHeight;

                if ((windowScroll >= contentBottom) && contents) {
                    fetchMoreContents(contents)
                        .catch(() => {});
                }
            }
        };
        if (contents && totalContent && contents?.length < totalContent && pushedMoreContents) {
            document.body.addEventListener('scroll', loadMore);
            if (contents?.length > 4) {
                setPushedMoreContents(true);
            }
        }
        return () => {
            document.body.removeEventListener('scroll', loadMore);
        };
    }, [contents?.length]);

    useEffect(() => {
        if (pushedMoreContents && contents) {
            fetchMoreContents(contents)
                .catch(() => {});
        }
    }, [pushedMoreContents]);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <ErrorPage />;
    }

    const onMoreVideoHandler = () => {
        if (setPushedMoreContents) {
            setPushedMoreContents(true);
        }
    };

    const renderItems = contents && contents.length
        ? contents.map((content: ContentFragment, index: number) => {
            if ((index % 3) === 0) {
                return (
                    <React.Fragment key={content.id}>
                        <Video
                            content={content}
                            authorized={authorized}
                            flowUrl={flowUrl}
                        />
                        <PromoBanner />
                    </React.Fragment>
                );
            }
            if (index === 3) {
                return (
                    <Video
                        key={content.id}
                        content={content}
                        authorized={authorized}
                        flowUrl={flowUrl}
                    />
                );
            }
            return (
                <Video
                    key={content.id}
                    content={content}
                    authorized={authorized}
                    flowUrl={flowUrl}
                />
            );
        }) : null;

    return (
        <div
            ref={contentRef}
        >
            {renderItems}
            {showLoader && <Loader />}
            {!pushedMoreContents
                ? (
                    <button type="button" className={s.moreButton} onClick={() => onMoreVideoHandler()}>
                        More video
                    </button>
                ) : null}
        </div>
    );
});

export default Videos;
