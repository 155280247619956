import React from 'react';
import ReactDOM from 'react-dom';
import { initializeApp } from 'firebase/app';
import './index.css';
import Root from './components/Root/Root';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import portalsInit from './utils/portals';
import portalsSelectors from './constants/portals';
import { firebaseConfig } from './constants/firebase';
import { firebaseRegistration } from './utils/firebase';

portalsInit(portalsSelectors);

initializeApp(firebaseConfig);

ReactDOM.render(
    <React.StrictMode>
        <Root />
    </React.StrictMode>,
    document.getElementById('root'),
);

// Service worker
serviceWorkerRegistration.register({
    onSuccess: firebaseRegistration,
    onUpdate: firebaseRegistration,
});
