import React, {
    FC, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconFullscreen } from '../../assets/images/expand-solid.svg';
import s from './VideoPlayer.module.scss';
import toggleFullScreen from '../../utils/toggleFullScreen';
import SpeakerButtons from './PlayPauseButtons';
import PlayPauseIcons from './PlayPauseIcons';
import PlayPauseBigButtons from './PlayPauseBigButtons';
import setCurrentPlayer from '../../actions/videoCurrentActions';
import { RootStateType } from '../../types/rootState';
import VideoPopup from '../Popup/VideoPopup';
import PromoBanner from '../PromoBanner/PromoBanner';

export type Props = {
    onPlay(): void,
    className?: string,
    poster: string,
    src: string,
    isPlayingProp?(boolean:boolean): void,
    authorized: boolean,
    flowUrl: string,
};

const VideoPlayer:FC<Props> = ({
    isPlayingProp,
    onPlay,
    poster,
    src,
    className,
    children,
    authorized,
    flowUrl,
}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [currentTimePercent, setCurrentTimePercent] = useState(0);
    const [currentTime, setCurrentTime] = useState('00:00');
    const [volume, setVolume] = useState(1);
    const [progressBar, setProgressBar] = useState(0);
    const refPlayer = useRef<HTMLVideoElement>(null);
    const refProgressBar = useRef<HTMLProgressElement>(null);
    const refPlayerWrapper = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();

    const lastVideoRef = useSelector((state: RootStateType) => state.videoCurrent);

    const togglePlay = () => {
        if (refPlayer.current && authorized) {
            if (lastVideoRef.current && lastVideoRef.current?.pause) {
                if (!lastVideoRef.current.isEqualNode(refPlayer.current)) {
                    lastVideoRef.current.pause();
                    lastVideoRef.setIsPlaying(false);
                }
            }

            setIsPlaying(!isPlaying);
            dispatch(setCurrentPlayer({
                current: refPlayer.current,
                setIsPlaying,
            }));

            if (refPlayer.current.paused && !refPlayer.current.ended) {
                refPlayer.current.play();
            } else {
                refPlayer.current.pause();
            }

            if (isPlayingProp) {
                isPlayingProp(!isPlaying);
            }
        } else {
            window.location.href = flowUrl;
        }
    };

    const seek = (e: { nativeEvent: { offsetX: number; }; }) => {
        if (!refPlayer.current) return;
        if (!refProgressBar.current) return;

        const percent = e.nativeEvent.offsetX / refProgressBar.current.offsetWidth;
        setProgressBar(Math.floor(percent * 100));
        if (refPlayer.current.duration) {
            refPlayer.current.currentTime = percent * refPlayer.current.duration;
        } else {
            setCurrentTimePercent(percent);
            togglePlay();
        }
    };

    const updateProgressBar = () => {
        if (!refPlayer.current) {
            return;
        }

        const percentage = Math.floor((100 / refPlayer.current.duration)
            * refPlayer.current.currentTime);
        setProgressBar(percentage);
    };

    const toggleFullScreenBtn = () => {
        toggleFullScreen(isFullScreen, refPlayerWrapper, setIsFullScreen);
    };

    const volumeHandler = (value:string) => {
        if (refPlayer.current) {
            setVolume(+value);
            refPlayer.current.volume = volume;
            if (+value <= 0) {
                setIsMuted(true);
            } else {
                setIsMuted(false);
            }
        }
    };

    const timeUpdateHandler = () => {
        if (refPlayer.current) {
            let S: number | string = refPlayer.current.currentTime;
            const m: number | string = Math.floor(S / 60);
            const outputSecondsMinutes = (m >= 10) ? m : `0${m}`;
            S = Math.floor(S % 60);
            const outputSeconds = (S >= 10) ? S : `0${S}`;

            setCurrentTime(`${outputSecondsMinutes}:${outputSeconds}`);
        }
    };

    const loadHandler = () => {
        if (refPlayer.current) {
            refPlayer.current.currentTime = currentTimePercent * refPlayer.current.duration;
        }
    };
    return (
        <>
            <div ref={refPlayerWrapper} className={classNames(s.player, isPlaying ? s.playing : '')}>
                <video
                    muted={isMuted}
                    ref={refPlayer}
                    onTimeUpdate={() => { timeUpdateHandler(); updateProgressBar(); }}
                    className={classNames(className, s.video, isPlaying ? 'playing' : '')}
                    onPlay={onPlay}
                    preload="none"
                    poster={poster}
                    onLoadedData={loadHandler}
                >
                    <track
                        src="/"
                        kind="captions"
                        srcLang="ru"
                    />
                    <source
                        type="video/mp4"
                        src={src}
                    />
                </video>

                {
                    !isPlaying
                    || (
                        <div id="videoPlayer-controls" className={s.controls}>
                            {/*  eslint-disable */}
                        <progress
                            className={s.progress}
                            onClick={seek}
                            ref={refProgressBar}
                            // @ts-ignore
                            min="0"
                            max="100"
                            value={progressBar}
                        >
                        </progress>
                        <div className={s.playPause}>
                            <PlayPauseIcons isPlaying={isPlaying} onClick={togglePlay} />
                        </div>
                        <div className={s.time}>
                            {currentTime}
                        </div>
                        <div className={s.controlsBtn}>
                            <SpeakerButtons
                                isMuted={isMuted}
                                setIsMuted={setIsMuted}
                            />
                            <input
                                onChange={(e) => volumeHandler(e.target.value)}
                                type="range"
                                title="volume"
                                min="0"
                                max="1"
                                step="0.1"
                                className={s.volume}
                                value={!isMuted ? volume : 0}
                            />

                            <button
                                type="button"
                                className={s.btnFullScreen}
                                title="toggle full screen"
                                onClick={() => toggleFullScreenBtn()}
                            >
                                <IconFullscreen />
                            </button>
                        </div>
                    </div>
)
                }

                {children}
                <PlayPauseBigButtons isPlaying={isPlaying} onClick={togglePlay}/>
                {
                    !isPlaying
                    || (
                        <>
                            <VideoPopup />
                            <button className={s.btnClose} onClick={togglePlay}/>
                            <PromoBanner />
                        </>
                    )
                }

            </div>
        </>
    );
};

VideoPlayer.defaultProps = {
    className: '',
    isPlayingProp(boolean: boolean) {
    },
}

export default VideoPlayer;
