import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import s from './Header.module.scss';
import logo from '../../assets/images/logo.png';
import logoSmall from '../../assets/images/logo-inner.png';

const Header: FC = () => {
    const location = useLocation();
    const isCardIdPage = location.pathname.indexOf('card_id') !== -1;
    return (
        <header id="header" className={isCardIdPage ? `${s.header} ${s.header_small}` : s.header}>
            <div className={s.wrapper}>
                <Link className={s.logoLink} to="/">
                    <img id="header_logo" className={s.logo} src={isCardIdPage ? logoSmall : logo} alt="" />
                </Link>
            </div>
        </header>
    );
};

export default Header;
