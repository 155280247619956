import React, { FC } from 'react';
import { ReactComponent as IconUnmuted } from '../../assets/images/volume-up-solid.svg';
import { ReactComponent as IconMuted } from '../../assets/images/volume-mute-solid.svg';

type PlayPauseButtonsType = {
    isMuted: boolean,
    setIsMuted(boolean: boolean): void
};

const PlayPauseButtons:FC<PlayPauseButtonsType> = ({ isMuted = false, setIsMuted }) => (
    !isMuted
        ? (
            <button
                type="button"
                onClick={() => setIsMuted(true)}
            >
                <IconUnmuted />
            </button>
        )
        : (
            <button
                type="button"
                onClick={() => setIsMuted(false)}
            >
                <IconMuted />
            </button>
        )
);

export default PlayPauseButtons;
