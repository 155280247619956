import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { Provider } from 'react-redux';
import App from '../App/App';
import configureStore from '../App/configureStore';
import { BASE_PATH } from '../../constants/api';

const store = configureStore();

export const client = new ApolloClient({
    uri: `${BASE_PATH}/api/query`,
    connectToDevTools: true,
    cache: new InMemoryCache({
        typePolicies: {
            Preview: {
                keyFields: ['id'],
            },
            Category: {
                keyFields: ['id'],
                fields: {
                    contents: offsetLimitPagination(),
                },
            },
            Tag: {
                keyFields: ['id'],
                fields: {
                    contents: offsetLimitPagination(),
                },
            },
            Service: {
                keyFields: ['id'],
                fields: {
                    contents: offsetLimitPagination(),
                },
            },
            Content: {
                keyFields: ['id'],
            },
            Like: {
                keyFields: ['id'],
            },
        },
    }),
});

const Root = () => (
    <ApolloProvider client={client}>
        <Provider store={store}>
            <BrowserRouter basename={BASE_PATH}>
                <App />
            </BrowserRouter>
        </Provider>
    </ApolloProvider>
);

export default Root;
