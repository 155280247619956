import React, { FC } from 'react';
import classNames from 'classnames';
import s from './VideoPlayer.module.scss';
import { ReactComponent as Pause } from '../../assets/images/pause.svg';

type PlayPauseBigButtonsType = {
    isPlaying: boolean,
    onClick(): void,
};

const PlayPauseBigButtons:FC<PlayPauseBigButtonsType> = ({ isPlaying, onClick }) => (
    isPlaying
        ? (
            <button
                type="button"
                className={classNames(s.overlay, s.togglePlayBtn)}
                onClick={onClick}
            >
                <Pause />
            </button>
        )
        : (
            <button
                type="button"
                className={classNames(s.overlay, s.togglePlayBtn)}
                onClick={onClick}
            >
                {/* <img src={play} alt="play" /> */}
                <div className={s.bigButton}>
                    <div className={s.bigButton_wrapper}>
                        <div className={s.bigButton_text}>
                            PLAY
                        </div>
                    </div>
                </div>
            </button>
        )
);

export default PlayPauseBigButtons;
