/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import useAddToHomeScreen from '../../api/useAddToHomeScreen';
import s from './AddToHomeScreen.module.scss';
import Portal from '../Portal/Portal';
import { PortalSelector } from '../../constants/portals';

function AddToHomeScreen() {
    const [canShow, setCanShow] = useState<boolean>(true);

    const isProd = process.env.NODE_ENV === 'production';

    const { prompt, promptToInstall } = useAddToHomeScreen();

    const canInstallApp = !!prompt;

    const handleClick = () => {
        // Отключаем возможность отображения невидимого блока,
        // когда по нему уже был клик, до след обновления страницы
        setCanShow(false);
        promptToInstall();
    };

    // Отображаем невидимый блок, только если есть поддержка prompt
    if (canInstallApp && canShow && isProd) {
        return (
            <Portal selector={PortalSelector.ADD_TO_HOME_SCREEN}>
                <div className={s.addToHomeScreen} onClick={handleClick} />
            </Portal>
        );
    }
    return null;
}

export default AddToHomeScreen;
