import {
    PropsWithChildren,
    useEffect,
    useRef,
    useState,
} from 'react';
import { createPortal } from 'react-dom';

// Interfaces
type IPortalProps = PropsWithChildren<{
    selector: string,
}>;

// Component
function Portal(props: IPortalProps) {
    const { children, selector } = props;

    const [mounted, setMounted] = useState<boolean>(false);

    const ref = useRef<HTMLElement | null>(null);

    const refCurrent = ref?.current;

    useEffect(() => {
        ref.current = document.querySelector(`#${selector}`);
        setMounted(true);
    }, [selector]);

    return (mounted && refCurrent) ? createPortal(children, refCurrent) : null;
}

export default Portal;
