export type IArr = string[];

const portalsInit = (selectors: IArr) => {
    selectors.map((item: string) => {
        const div = document.createElement('div');
        div.id = item;
        document.body.appendChild(div);
        return null;
    });
    return null;
};

export default portalsInit;
