export const BASE_PATH = '/ero';

export default {
    footer: `${BASE_PATH}/text/life-rb-ero/footer-main-page/1`,
    offer: `${BASE_PATH}/text/life-rb-ero/oferta/1`,
    headerSupport: `${BASE_PATH}/text/life-rb-ero/header-support/1`,
    subscriptioncosts: `${BASE_PATH}/text/life-rb-ero/subscriptioncosts/1`,
    conditions: `${BASE_PATH}/text/life-rb-ero/conditions/1`,
    welcome: `${BASE_PATH}/text/life-rb-ero/welcome/1`,
    categories: `${BASE_PATH}/api/compact-auto`,
    getViews: `${BASE_PATH}/bapi/views`,
    getLikes: `${BASE_PATH}/bapi/likes`,
    getLikesAuth: `${BASE_PATH}/bapi/auth//likes`,
    play: `${BASE_PATH}/bapi/auth/play/`,
    like: `${BASE_PATH}/bapi/auth/like/`,
    getViewed: `${BASE_PATH}/bapi/viewed`,
    page404: `${BASE_PATH}/404`,
    error: `${BASE_PATH}/error`,
    backUrl: 'http://smotri.club/ero/default/landing/lp/flow/ksua_ero?lpcontext=default&source=service',
    spamUrl: 'https://google.com',
    firebaseToken: `${BASE_PATH}/firebase/token`,
};
